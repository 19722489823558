<template>
    <!-- 第三方订单列表页面 -->
    <div class="padding24 color_white" id="third_Party_order">
      <a-spin :spinning="spinning" size="large" :tip="tip">
        <div>
          <div class="top">
            <a-form layout="inline">
              <a-form-item label="第三方订单编号">
               <a-input
                 v-model="orderId"
                 placeholder="请输入订单编号"
                 @pressEnter="searchClick"
               ></a-input>
              </a-form-item>
              <a-form-item label="用户手机号">
               <a-input
                 v-model="deliveryTelNumber"
                 placeholder="请输入用户手机号"
                 @pressEnter="searchClick"
               ></a-input>
              </a-form-item>
              <a-form-item>
                <a-button
                  icon="search"
                  type="primary"
                  @click="searchClick"
                  :disabled="isDisableSearch"
                >
                  搜索
                </a-button>
              </a-form-item>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="tableData"
            :pagination="false"
            :loading="tableLoading"
            :scroll="{ x: 1500 }"
          >
            <span slot="payMethod" slot-scope="payMethod, record">
              <span>{{record.payMethod}}</span>
              <!-- <span v-if="record.payMethod == 0">货到付款</span>
              <span v-if="record.payMethod == 1">微信</span>
              <span v-if="record.payMethod == 2">支付宝</span>
              <span v-if="record.payMethod == 3">小程序</span>
              <span v-if="record.payMethod == 4">银行卡</span>
              <span v-if="record.payMethod == 5">余额</span>
              <span v-if="record.payMethod == 7">免费</span>
              <span v-if="record.payMethod == 8">DOU分期</span>
              <span v-if="record.payMethod == 9">新卡支付</span> -->
            </span>
            <span slot="status" slot-scope="status, record">
              <span>{{statusText[record.status]}}</span>
              <!-- <span v-if="record.status == 1">待确认</span>
              <span v-if="record.status == 105">已支付</span>
              <span v-if="record.status == 2">备货中</span>
              <span v-if="record.status == 101">部分发货</span>
              <span v-if="record.status == 3">已发货</span>
              <span v-if="record.status == 4">已取消</span>
              <span v-if="record.status == 5">已完成</span> -->
            </span>
            <span slot="receiveStatus" slot-scope="receiveStatus, record">
              <span v-if="record.receiveStatus == 0">未领取</span>
              <span v-if="record.receiveStatus == 1">已领取</span>
            </span>
            <span slot="productTitle" slot-scope="productTitle, record">
              <span v-if="record.productTitle != ''">{{ record.productTitle }}</span>
              <span v-else>——</span>
            </span>
            <span slot="productType" slot-scope="productType">
              {{productTypeText[productType]}}
              <!-- <span v-if="record.courseName">课程</span>
              <span v-if="record.memberName">会员</span>
              <span v-if="record.memberName == '' && record.courseName == ''"
                >——</span
              > -->
            </span>
            <span slot="orderPrice" slot-scope="orderPrice, record">
              {{handlePrice(record.orderPrice)}}
            </span>
            <span slot="operation" slot-scope="operation, record">
              <a
                id="copy_memberNo"
                type="primary"
                class="margin_right20"
                @click="appShow(record.orderId)"
              >
                修改手机号
              </a>
            </span>
          </a-table>
           <a-modal
            title="修改手机号"
            :width="640"
            :visible="visible"
            :confirmLoading="confirmLoading"
            okText="确定"
            cancelText="取消"
            @ok="handleOk"
            @cancel="handleCancel"
          >
            <a-form-model
              ref="form"
              :model="form"
              :rules="rules"
              :label-col="{ span: 7 }"
              :wrapperCol="{ span: 13 }"
            >
              <a-form-model-item label="手机号码" prop="labelPhone">
                <a-input
                  v-model="form.labelPhone"
                  placeholder="请输入手机号码"
                ></a-input>
              </a-form-model-item>
            </a-form-model>
          </a-modal>
          <MyPagination
            :count="count"
            :pageNo="pageNo"
            @showSizeChangeFn="showSizeChangeFn"
            v-if="tableData.length > 0"
          />
          <!-- 返回顶部 -->
          <a-back-top :target="targetFn" :visibilityHeight="100" />
        </div>
      </a-spin>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import {
    GetWxThreeOrdersApi,
    WxOrderSyncApi,
    WxOrderSyncExportApi,
    UpOrderPhoneApi
  } from "@/request/api/orderformManage";
  import MyPagination from "@/components/pagination/MyPagination";
  import { message } from "ant-design-vue";
  import { codeFn } from "@/utils/tools";
  export default {
    components: { MyPagination },
    created() {
      let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
      dictionaryObj.orderType.forEach((item) => this.orderTypeArr.push(item));
      // dictionaryObj.tiktokShopOrderStatus.forEach((item) =>
      //   this.tiktokShopOrderStatusArr.push(item)
      // );
      const statusText = this.statusText;
      for (let i in statusText) {
        this.tiktokShopOrderStatusArr.push({
          key: i,
          value: statusText[i]
        });
      }
      dictionaryObj.tiktokShopPayType.forEach((item) =>
        this.tiktokShopPayTypeArr.push(item)
      );
      if (codeFn("/admin/order/sync")) this.isShowOrderSync = true;
      if (codeFn("/admin/order/sync/export")) this.isShowOrderExport = true;
    },
    data() {
      return {
        spinning: false, // 全局loading
        tip: "",
        pageNo: 1,
        pageSize: 20,
        count: 0,
        orderId: "",
        deliveryTelNumber: "",
        productTitle: "",
        productId: "",
        commodityCode: "",
        orderType: "", // 商品类型
        orderTypeArr: [{ key: "", value: "全部类型" }],
        tiktokShopOrderStatus: "", // 订单状态的默认值
        tiktokShopOrderStatusArr: [{ key: "", value: "全部状态" }], //订单状态的数组
        tiktokShopPayType: "", // 支付方式默认值
        tiktokShopPayTypeArr: [{ key: "", value: "全部状态" }], // 支付方式的数组
        receive: "0", // 领取方式
        receiveArr: [
          { key: "", value: "全部" },
          { key: "0", value: "未领取" },
          { key: "1", value: "已领取" },
        ],
        rangePicker: [], // 下单日期数组
        startPayTime: "",
        endPayTime: "",
        isDisableSearch: false, // 搜索是否禁用
        tableLoading: false,
        columns: [
          {
            title: "微信小店订单编号",
            dataIndex: "orderId",
            key: "orderId",
            width: "8%",
            scopedSlots: { customRender: "orderId" },
          },
          {
            title: "商品名称",
            dataIndex: "productTitle",
            key: "productTitle",
            width: "15%",
            scopedSlots: { customRender: "productTitle" },
          },
          {
            title: "商品类型",
            dataIndex: "productType",
            key: "productType",
            width: "4%",
            scopedSlots: { customRender: "productType" },
          },
          {
            title: "微信小店商品编码",
            dataIndex: "productId",
            key: "productId",
            width: "8%",
          },
          // {
          //   title: "商家编码",
          //   dataIndex: "commodityCode",
          //   key: "commodityCode",
          //   width: "8%",
          // },
          {
            title: "支付金额",
            dataIndex: "orderPrice",
            key: "orderPrice",
            width: "4%",
            scopedSlots: { customRender: "orderPrice" },
          },
          {
            title: "优惠总金额",
            dataIndex: "promotionAmount",
            key: "promotionAmount",
            width: "5%",
          },
          {
            title: "用户手机号",
            dataIndex: "deliveryTelNumber",
            key: "deliveryTelNumber",
            width: "6%",
          },
          {
            title: "收件人",
            dataIndex: "deliveryUserName",
            key: "deliveryUserName",
            width: "4%",
          },
          {
            title: "支付类型",
            dataIndex: "payMethod",
            key: "payMethod",
            width: "4%",
            scopedSlots: { customRender: "payMethod" },
          },
          {
            title: "订单状态",
            dataIndex: "status",
            key: "status",
            width: "4%",
            scopedSlots: { customRender: "status" },
          },
          {
            title: "领取状态",
            dataIndex: "receiveStatus",
            key: "receiveStatus",
            width: "4%",
            scopedSlots: { customRender: "receiveStatus" },
          },
          {
            title: "下单时间",
            dataIndex: "createTime",
            width: "5%",
            sorter: true,
            key: "createTime",
            sorter: (a, b) => {
              let aTime = new Date(a.createTime).getTime();
              let bTime = new Date(b.createTime).getTime();
              return aTime - bTime;
            },
          },
          {
            title: "支付时间",
            dataIndex: "payTime",
            width: "5%",
            sorter: true,
            key: "payTime",
            sorter: (a, b) => {
              let aTime = new Date(a.payTime).getTime();
              let bTime = new Date(b.payTime).getTime();
              return aTime - bTime;
            },
          },
          {
            title: "订单完成时间",
            dataIndex: "finishTime",
            width: "6%",
            sorter: true,
            key: "finishTime",
            sorter: (a, b) => {
              let aTime = new Date(a.finishTime).getTime();
              let bTime = new Date(b.finishTime).getTime();
              return aTime - bTime;
            },
          },
          {
            title: "操作",
             fixed: "right",
             width:130,
            key: "operation",
            dataIndex: "operation",
            scopedSlots: { customRender: "operation" },
          }
          // {
          //   title: "推广类型",
          //   dataIndex: "sendPay",
          //   key: "receiveStatussendPay",
          //   //   width: "13%",
          //   scopedSlots: { customRender: "sendPay" },
          // },
        ],
        tableData: [],
        isShowOrderSync: false, // 同步订单是否隐藏
        isShowOrderExport: false, // 同步订单是否隐藏
        statusText: {
          10: '待付款',
          15: '待成团',
          16: '待接单',
          17: '待核销',
          20: '待发货',
          21: '部分发货',
          30: '待收货',
          100: '已完成',
          200: '订单取消',
          250: '已取消'
        },
        productTypeText: {
          0: '未知',
          1: '课程',
          2: '会员'
        },
         visible: false,
        form: {
          labelPhone: "",
        },
        rules: {
          labelPhone: [
            { required: true, validator: this.validatePhone, trigger: "blur" },
          ],
        },
        confirmLoading: false,
      };
    },
    methods: {
      moment,
        // 标签提交
      handleOk() {
        this.confirmLoading = true;
        UpOrderPhoneApi({
          types: "2",
          orderNo: this.orderNoNew,
          phone: this.form.labelPhone,
        }).then((res) => {
          if (res.code === 200) {
            this.visible = false;
            this.confirmLoading = false;
            this.$refs.form.resetFields(); // 重置表格
            message.success("修改成功");
            this.GetThreeOrdersFn();
          } else {
            setTimeout(() => {
              this.confirmLoading = false;
            }, 2000);
          }
        });
      },
      // 标签取消或关闭
      handleCancel() {
        this.visible = false;
        this.$refs.form.resetFields(); // 重置表格
      },
      // 手机号校验规则
      validatePhone(rule, value, callback) {
        let reg = /^1(3|4|5|6|7|8|9)\d{9}$/;
        if (value === "") {
          callback("输入的内容不能为空");
        } else if (!reg.test(value)) {
          callback("请输入正确的手机号");
        } else {
          callback();
        }
      },
      appShow(no) {
        this.orderNoNew = no;
        this.visible = true;
      },
      // 处理价格
      handlePrice(v) {
        let priceStr = '';
        if (v) {
          priceStr = (v / 100).toFixed(2);
        }
        return priceStr;
      },
      // 商品类型改变的回调
      changeOrderType(value) {
        this.pageNo = 1;
        this.orderType = value;
        this.GetThreeOrdersFn();
      },
      // 订单状态改变的回调
      changeTiktokShopOrderStatus(value) {
        this.pageNo = 1;
        this.tiktokShopOrderStatus = value;
        this.GetThreeOrdersFn();
      },
      // 支付方式改变的回调
      changeTiktokShopPayType(value) {
        this.pageNo = 1;
        this.tiktokShopPayType = value;
        this.GetThreeOrdersFn();
      },
      // 领取方式改变的回调
      changeReceive(value) {
        this.pageNo = 1;
        this.receive = value;
        this.GetThreeOrdersFn();
      },
      // 下单日期改变的回调
      onChange(date, dateString) {
        if (dateString[0] != "") {
          this.startPayTime = dateString[0];
          this.endPayTime = dateString[1];
          this.rangePicker = [
            moment(dateString[0], "YYYY-MM-DD HH:mm:ss"),
            moment(dateString[1], "YYYY-MM-DD HH:mm:ss"),
          ];
        } else {
          this.startPayTime = "";
          this.endPayTime = "";
          this.rangePicker = [];
        }
      },
      // 搜索的按钮
      searchClick() {
        this.pageNo = 1;
        this.isDisableSearch = true;
        setTimeout(() => {
          this.isDisableSearch = false;
        }, 3000);
        this.GetThreeOrdersFn();
      },
      // 重置按钮
      resetClick() {
        this.orderId = "";
        this.deliveryTelNumber = "";
        this.productTitle = "";
        this.orderType = "";
        this.tiktokShopOrderStatus = "";
        this.productId = "";
        this.commodityCode = "";
        this.tiktokShopPayType = "";
        this.receive = "";
        this.rangePicker = [];
        this.startPayTime = "";
        this.endPayTime = "";
      },
      // 同步第三方订单按钮
      synchronousClick() {
        let date1 = new Date("2021-07-22 18:00:00:000");
        let time1 = Date.parse(date1);
        let date2 = new Date(this.startPayTime);
        let time2 = Date.parse(date2);
        this.tip = "同步第三方订单中";
        this.spinning = true;
        if (this.startPayTime == "" || this.endPayTime == "") {
          message.error("请选择支付时间");
          this.spinning = false;
        } else if (time1 > time2) {
          message.error("请选择同步七月二十二号六点之后的订单");
          this.spinning = false;
        } else {
          WxOrderSyncApi({
            pageSize: this.pageSize,
            endPayTime: this.endPayTime,
            startPayTime: this.startPayTime,
          }).then(({ code, data }) => {
            if (code == 200) {
              message.success("同步第三方订单成功");
              this.GetThreeOrdersFn();
              this.spinning = false;
            } else {
              this.spinning = false;
            }
          });
        }
      },
      // 导出订单按钮
      exportClick() {
        this.tip = "订单导出中";
        this.spinning = true;
        WxOrderSyncExportApi({
          orderId: this.orderId, // 订单编号
          deliveryTelNumber: this.deliveryTelNumber, // 手机号
          productTitle: this.productTitle, // 商品名称
          productId: this.productId, // 微信小店商品编码
          commodityCode: this.commodityCode, // 商品编码
          productType: this.orderType, // 商品类型
          status: this.tiktokShopOrderStatus, // 订单状态
          payMethod: this.tiktokShopPayType, // 支付方式
          receiveStatus: this.receive, // 是否领取
          startPayTime: this.startPayTime,
          endPayTime: this.endPayTime,
        }).then(({ code, data }) => {
          if (code == 200) {
            window.location.href = data;
            this.spinning = false;
            message.success("导出订单成功");
          } else {
            this.spinning = false;
          }
        });
      },
      // 分页功能切换的回调
      showSizeChangeFn(current, pageSize) {
        this.pageNo = current;
        this.pageSize = pageSize;
        this.GetThreeOrdersFn();
      },
      // 获取第三方订单列表
    GetThreeOrdersFn () {
        if (!this.orderId && !this.deliveryTelNumber) {
            this.tableData = []
            return
        }
        this.tableLoading = true;
        GetWxThreeOrdersApi({
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          orderId: this.orderId, // 订单编号
          deliveryTelNumber: this.deliveryTelNumber, // 手机号
          productTitle: this.productTitle, // 商品名称
          productId: this.productId, // 微信小店商品编码
          commodityCode: this.commodityCode, // 商品编码
          productType: this.orderType, // 商品类型
          status: this.tiktokShopOrderStatus, // 订单状态
          payMethod: this.tiktokShopPayType, // 支付方式
          receiveStatus: this.receive, // 是否领取
          startPayTime: this.startPayTime,
          endPayTime: this.endPayTime,
        }).then(({ code, data }) => {
          if (code == 200) {
            this.tableLoading = false;
            this.count = data.count;
            data.data.forEach((item) => {
              item.key = item.orderId;
              item.deliveryUserName = item.deliveryUserName || "——";
              item.payTime = item.payTime == null ? "——" : item.payTime;
              item.finishTime = item.finishTime == null ? "——" : item.finishTime;
            });
            this.tableData = data.data;
          }
        });
      },
      // 回到顶部的函数
      targetFn() {
        return document.querySelector("#third_Party_order");
      },
    },
  };
  </script>
   
  <style lang="less" scoped>
  #third_Party_order {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }
  .top {
    .top_line {
      margin-bottom: 20px;
      display: flex;
      span {
        margin-right: 20px;
        /deep/.ant-input {
          width: 220px;
        }
        /deep/.ant-select-selection {
          width: 110px;
          margin-left: 10px;
        }
        /deep/.ant-calendar-picker-input {
          margin-left: 10px;
          width: auto;
        }
        /deep/.ant-calendar-picker {
          width: 400px !important;
        }
      }
      .ant-btn {
        margin-right: 20px;
      }
    }
  }
  </style>