<template>
  <!-- 第三方订单列表页面 -->
  <div class="padding24 color_white" id="third_Party_order">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <div>
        <div class="top">
          <a-form layout="inline">
            <a-form-item label="小红书订单编号">
             <a-input
               v-model="filter.orderNo"
               placeholder="请输入订单编号"
               @pressEnter="searchClick"
             ></a-input>
            </a-form-item>
            <a-form-item label="用户手机号">
             <a-input
               v-model="filter.phone"
               placeholder="请输入用户手机号"
               @pressEnter="searchClick"
             ></a-input>
            </a-form-item>
            <a-form-item label="商品名称">
             <a-input
               v-model="filter.skuName"
               placeholder="请输入商品名称"
               @pressEnter="searchClick"
             ></a-input>
            </a-form-item>
          
            <a-form-item label="订单状态">
              <a-select
                v-model="filter.orderStatus"
                :getPopupContainer="
                  (triggerNode) => triggerNode.parentNode || document.body
                "
                style="width: 120px;"
                @change="changeorderStatus"
              >
                <a-select-option
                  v-for="item in orderStatusArr"
                  :key="item.key"
                >
                  {{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-item>
           
            <a-form-item label="领取状态">
              <a-select
                v-model="filter.receiveStatus"
                :getPopupContainer="
                  (triggerNode) => triggerNode.parentNode || document.body
                "
                style="width: 120px;"
                @change="changeReceive"
              >
                <a-select-option v-for="item in receiveArr" :key="item.key">
                  {{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="SKU ID">
             <a-input
               v-model="filter.skuId"
               placeholder="请输入SKU ID"
               @pressEnter="searchClick"
             ></a-input>
            </a-form-item>
            <a-form-item label="支付时间">
              <a-range-picker
                :value="rangePicker"
                show-time
                format="YYYY-MM-DD HH:mm:ss"
                @change="onChange"
              />
            </a-form-item>
            <a-form-item>
              <a-button
                icon="search"
                type="primary"
                @click="searchClick"
                :disabled="isDisableSearch"
              >
                搜索
              </a-button>
            </a-form-item>
            <a-form-item>
              <a-button icon="redo" type="primary" @click="resetClick">
                重置
              </a-button>
            </a-form-item>
          </a-form>
          <a-form layout="inline">
            <a-form-item>
              <a-button
                v-if="isShowOrderSync"
                icon="ordered-list"
                type="primary"
                @click="synchronousClick"
              >
                同步第三方订单
              </a-button>
            </a-form-item>
            <a-form-item>
              <a-button
                v-if="isShowOrderExport"
                icon="export"
                type="primary"
                @click="exportClick"
              >
                导出订单
              </a-button>
            </a-form-item>
          </a-form>
        </div>
        <!-- 表格 -->
        <a-table
          :columns="columns"
          :data-source="tableData"
          :pagination="false"
          :loading="tableLoading"
          :scroll="{ x: 2000, y: 520 }"
          key="id"
        >
          <span slot="receiveStatus" slot-scope="receiveStatus, record">
            <span v-if="record.receiveStatus == 0">未领取</span>
            <span v-if="record.receiveStatus == 1">已领取</span>
          </span>
          <span slot="skuName" slot-scope="skuName, record">
            <span v-if="record.skuName != ''">{{ record.skuName }}</span>
            <span v-else>——</span>
          </span>
          <span slot="productType" slot-scope="productType">
            {{productTypeText[productType]}}
            <!-- <span v-if="record.courseName">课程</span>
            <span v-if="record.memberName">会员</span>
            <span v-if="record.memberName == '' && record.courseName == ''"
              >——</span
            > -->
          </span>
          <span slot="orderPrice" slot-scope="orderPrice, record">
            {{handlePrice(record.orderPrice)}}
          </span>
          <!-- <span slot="operation" slot-scope="operation, record"></span> -->
        </a-table>

        <MyPagination
          :count="count"
          :pageNo="filter.pageNo"
          @showSizeChangeFn="showSizeChangeFn"
          v-show="!tableLoading"
        />
        <!-- 返回顶部 -->
        <a-back-top :target="targetFn" :visibilityHeight="100" />
      </div>
    </a-spin>

    <!--    导出弹框    -->
    <a-modal v-model="visibleShow" title="导出">
        <template #footer>
            <div style="display: flex;justify-content: flex-end">
                <a-button @click="clickExportBtn(false)" class="margin_right20">取消</a-button>
                <download-excel
                    :data="tableData"
                    :fields="tableDataName"
                    name="小红书订单数据.xls">
                    <a-button type="primary" @click="clickExportBtn(true)">确定</a-button>
                </download-excel>
            </div>
        </template>
        <p>
            <a-icon type="info-circle" :style="{fontSize: '16px', color: '#FF8200'}"/>
            <span style="margin-left: 10px">确定要导出此数据吗？</span>
        </p>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import {
  getRedBookOrdersAPI,
  syncRedBookOrdersAPI,
} from "@/request/api/orderformManage";
import MyPagination from "@/components/pagination/MyPagination";
import { message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";
export default {
  components: { MyPagination },
  created() {
    this.fetchData();
    this.orderStatusArr = [
      {
        key: 0,
        value: '全部状态'
      },
      {
        key: 4,
        value: '待发货'
      },
      {
        key: 5,
        value: '部分发货'
      },
      {
        key: 6,
        value: '待收货'
      },
      {
        key: 7,
        value: '已完成'
      },
      {
        key: 8,
        value: '已关闭'
      },
      {
        key: 9,
        value: '已取消'
      },
      {
        key: 10,
        value: '换货申请中'
      },
    ]
    if (codeFn("syncRedBookOrder")) this.isShowOrderSync = true;
    if (codeFn("exportRedBookOrder")) this.isShowOrderExport = true;
  },
  data() {
    return {
      visibleShow: false,
      spinning: false, // 全局loading
      tip: "",
      filter: {
        pageNo: 1,
        pageSize: 20,
        orderNo: "",
        phone: '',
        skuName: '',
        orderStatus: 0, // 订单状态的默认值
        receiveStatus: 0,
        productId: '',
        payTimeStart: "",
        payTimeEnd: "",
        action: 1
      },
      
      
      count: 0,
      
      orderType: "", // 商品类型
      orderTypeArr: [{ key: "", value: "全部类型" }],
      orderStatusArr: [], //订单状态的数组
      
      receiveArr: [
        { key: 0, value: "全部状态" },
        { key: 1, value: "未领取" },
        { key: 2, value: "已领取" },
      ],
      rangePicker: [], // 下单日期数组
      
      isDisableSearch: false, // 搜索是否禁用
      tableLoading: true,
      columns: [
        {
          title: "小红书订单编号",
          dataIndex: "orderNo",
          key: "orderNo",
          width: 200,
          scopedSlots: { customRender: "orderNo" },
        },
        {
          title: "商品名称",
          dataIndex: "skuName",
          key: "skuName",
          width: 280,
          scopedSlots: { customRender: "skuName" },
        },
        // {
        //   title: "是否绑定课程/会员",
        //   dataIndex: "productType",
        //   key: "productType",
        //   width: 100,
        //   scopedSlots: { customRender: "productType" },
        // },
        {
          title: "SKU ID",
          dataIndex: "skuId",
          key: "skuId",
          width: 200,
        },
        {
          title: "支付金额",
          dataIndex: "price",
          key: "price",
          width: 100,
          scopedSlots: { customRender: "price" },
        },
        {
          title: "用户手机号",
          dataIndex: "phone",
          key: "phone",
          width: 120,
        },
        {
          title: "店铺名称",
          dataIndex: "shopName",
          key: "shopName",
          width: 120,
        },
        {
          title: "订单状态",
          dataIndex: "orderStatusText",
          key: "orderStatusText",
          width: 100,
        },
        {
          title: "领取状态",
          dataIndex: "receiveStatus",
          key: "receiveStatus",
          width: 100,
          scopedSlots: { customRender: "receiveStatus" },
        },
        {
          title: "下单时间",
          dataIndex: "orderCreateTime",
          width: 200,
          key: "orderCreateTime",
        },
        {
          title: "支付时间",
          dataIndex: "orderPayTime",
          width: 200,
          key: "orderPayTime",
        },
        {
          title: "领取时间",
          dataIndex: "receiveTime",
          width: 150,
          key: "receiveTime",
        },
        // {
        //   title: "操作",
        //    fixed: "right",
        //    width:130,
        //   key: "operation",
        //   dataIndex: "operation",
        //   scopedSlots: { customRender: "operation" },
        // }
      ],
      tableData: [],
      isShowOrderSync: false, // 同步订单是否隐藏
      isShowOrderExport: false, // 同步订单是否隐藏
      
      productTypeText: {
        0: '未知',
        1: '课程',
        2: '会员'
      },
       visible: false,
      form: {
        labelPhone: "",
      },
      rules: {
        labelPhone: [
          { required: true, validator: this.validatePhone, trigger: "blur" },
        ],
      },
      confirmLoading: false,
      tableDataName: {
        "小红书订单编号": "orderNo",
        "商品名称": "skuName",
        "商品ID/SKU ID": "skuId",
        "支付金额": "price",
        "手机号": "phone",
        "店铺名称":"shopName",
        "领取状态": {
            field: "receiveStatus",
            callback: value => {
                return +value === 0 ? '未领取' : '已领取'
            }
        },
        "订单状态": "orderStatusText",
        "下单时间": "orderCreateTime",
        "支付时间": "orderPayTime",
        "领取时间":"receiveTime",
      },
    };
  },
  methods: {
    moment,
    // 点击确认导出按钮
    clickExportBtn(flag) {
      this.spinning = false
      this.visibleShow = false
      if (flag) this.$message.success('导出成功')
    },
    // 标签取消或关闭
    handleCancel() {
      this.visible = false;
      this.$refs.form.resetFields(); // 重置表格
    },
    // 手机号校验规则
    validatePhone(rule, value, callback) {
      let reg = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (value === "") {
        callback("输入的内容不能为空");
      } else if (!reg.test(value)) {
        callback("请输入正确的手机号");
      } else {
        callback();
      }
    },
    appShow(no) {
      this.orderNoNew = no;
      this.visible = true;
    },
    // 处理价格
    handlePrice(v) {
      let priceStr = '';
      if (v) {
        priceStr = (v / 100).toFixed(2);
      }
      return priceStr;
    },
    // 商品类型改变的回调
    changeOrderType(value) {
      this.filter.pageNo = 1;
      this.orderType = value;
      this.fetchData();
    },
    // 订单状态改变的回调
    changeorderStatus(value) {
      this.filter.pageNo = 1;
      this.orderStatus = value;
      this.fetchData();
    },
    // 领取方式改变的回调
    changeReceive(value) {
      this.filter.pageNo = 1;
      this.receive = value;
      this.fetchData();
    },
    // 下单日期改变的回调
    onChange(date, dateString) {
      if (dateString[0] != "") {
        this.filter.payTimeStart = dateString[0];
        this.filter.payTimeEnd = dateString[1];
        this.rangePicker = [
          moment(dateString[0], "YYYY-MM-DD HH:mm:ss"),
          moment(dateString[1], "YYYY-MM-DD HH:mm:ss"),
        ];
      } else {
        this.filter.payTimeStart = "";
        this.filter.payTimeEnd = "";
        this.rangePicker = [];
      }
    },
    // 搜索的按钮
    searchClick() {
      this.filter.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.fetchData();
    },
    // 重置按钮
    resetClick() {
      this.filter = this.$options.data().filter
      this.rangePicker = [];
    },
    // 同步第三方订单按钮
    synchronousClick() {
      syncRedBookOrdersAPI({
        source: 'xhs',
        "order_id": this.filter.orderNo
      }).then(({ code, data }) => {
        if (code == 200) {
          message.success("同步第三方订单成功");
          this.fetchData();
          this.spinning = false;
        } else {
          this.spinning = false;
        }
      });
    },
    // 导出订单按钮
    async exportClick() {
      this.tip = "订单导出中";
      this.spinning = true;
      this.tableLoading = true;
      const params = {
        ...this.filter,
        action: 2
      }
      const { code, data } = await getRedBookOrdersAPI(params)
      this.tableLoading = false;
      this.spinning = true;
      if (code !== 200) return
      this.tableData = data.orderList
      if (data.orderList.length > 0) {
        this.visibleShow = true
        setTimeout
      } else {
        this.$message.warning('暂无导出数据')
      }
      
      
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.filter.pageNo = current;
      this.filter.pageSize = pageSize;
      this.fetchData();
    },
    // 获取第三方订单列表
    async fetchData() {
      this.tableLoading = true;
      const { code, data } = await getRedBookOrdersAPI({ ...this.filter })
      this.tableLoading = false;
      if (code !== 200) return
      this.count = data.count;
      data.orderList.forEach((item) => {
        item.key = item.id;
        item.receiveTime = item.receiveTime || "——";
      });
      this.tableData = data.orderList;
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#third_Party_order");
    },
  },
};
</script>
 
<style lang="less" scoped>
#third_Party_order {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.top {
  .top_line {
    margin-bottom: 20px;
    display: flex;
    span {
      margin-right: 20px;
      /deep/.ant-input {
        width: 220px;
      }
      /deep/.ant-select-selection {
        width: 110px;
        margin-left: 10px;
      }
      /deep/.ant-calendar-picker-input {
        margin-left: 10px;
        width: auto;
      }
      /deep/.ant-calendar-picker {
        width: 400px !important;
      }
    }
    .ant-btn {
      margin-right: 20px;
    }
  }
}
</style>